import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { css } from 'twin.macro'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Layout from '../components/layout'
/**
 * truncate string
 * @param {string} string
 * @param {number} limit
 * @returns text...
 */
const truncate = (string, limit) =>
	string.length <= limit ? string : string.slice(0, limit) + '...'
/**
 * @typedef {object} PostNode
 * @prop {string} title
 * @prop {string} description
 * @prop {string[]} keywords
 * @prop {string} slug
 * @prop {string} category
 * @prop {string} date
 * @prop {string[]} tag
 * @prop {{file:{url:string}}} image
 * @prop {{raw:string,references:object}} content
 */
/**
 * @typedef {object} PostProps
 * @prop {object} data
 * @prop {object} data.postData
 * @prop {[{node: PostNode}]} data.postData.edges
 */
/**
 * Post template
 * @param {PostProps} props
 * @returns {JSX.Element} Render post template component
 */
const PostTemplate = (props) => {
	const {
		title,
		description,
		keywords,
		slug,
		category,
		date,
		tag,
		image,
		content,
	} = props.data.postData.edges[0].node
	const options = {
		/**
		 * Defines custom html string for each node type like heading, embedded entries etc..
		 */
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
			[INLINES.HYPERLINK]: (node, children) => {
				return (
					<a
						href={node.data.uri}
						tw="!whitespace-nowrap !inline-block !font-medium !underline !text-blue-500 hover:!text-blue-400"
					>
						{children}
					</a>
				)
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				const { gatsbyImageData, file, alt, title } = node.data.target
				if (!!gatsbyImageData)
					return (
						<GatsbyImage
							image={gatsbyImageData}
							alt={alt}
							title={title}
							tw="max-w-screen-md"
						/>
					)
				if (/video/i.test(file.contentType))
					return (
						<video
							title={title}
							className="lazy max-w-screen-md"
							controls
							preload="none"
							poster={image.file.url + '?fm=webp&w=768&q=50'}
							width="100%"
							height="100%"
							loop
						>
							<source src={file.url} type={file.contentType} />
						</video>
					)
				return null
			},
		},
		/**
		 * Defines custom html string for each mark type like bold, italic etc..
		 */
		renderMark: {
			[MARKS.BOLD]: (text) => <b>{text}</b>,
			[MARKS.CODE]: (text) =>
				!/iframe/i.test(text) ? (
					text
				) : (
					<iframe
						title={title}
						width="100%"
						height="100%"
						src={text.match(/src="(.*?)"/i)[1]}
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						loading="lazy"
						allowfullscreen=""
						tw="max-w-screen-md"
						style={{ minHeight: '340px' }}
					/>
				),
		},
	}
	// Dymanic SEO Title
	const seoTitle = () => {
		if (slug === 'how-to-look-after-a-mineral-swim-pool') {
			return 'Pool Maintenance | How to maintain a Mineral Swim Pool'
		}
		if (
			slug ===
			'traditional-chlorine-salt-or-mineral-pools-what-is-the-difference'
		) {
			return 'Types of swimming pool | Chlorine pool, Saltwater or Mineral pool; What is the Difference?'
		}
		if (slug === 'take-the-dead-sea-home') {
			return 'Dead Sea Pool | Take the Dead Sea home'
		}
		if (slug === 'hydrotherapy-through-mineral-pools') {
			return 'Hydrotherapy through Mineral Pools: Revitalize Your Body | MineralSwim'
		}
		if (slug === 'mineral-spas-and-natural-pools') {
			return 'Discover the Serenity of Mineral Spas and Natural Pools | MineralSwim'
		}
		if (
			slug === 'the-difference-between-saltwater-mineral-and-magnesium-pools'
		) {
			return 'Saltwater, Mineral, and Magnesium Pools Explained | Mineral Swim™'
		}
		if (slug === 'magnesium-pool-benefits') {
			return 'Magnesium Pool: Unveiling the Health Benefits | Mineral Swim™'
		} else {
			return `Mineral Swim | ${title}`
		}
	}
	// Dymanic SEO H1
	const seoH1 = () => {
		if (slug === 'how-to-look-after-a-mineral-swim-pool') {
			return 'Mineral swimming pool maintenance'
		}
		if (
			slug ===
			'traditional-chlorine-salt-or-mineral-pools-what-is-the-difference'
		) {
			return 'What is the difference between Chlorine pool, Saltwater or Mineral pool?'
		}
		if (slug === 'evas-story') {
			return 'Mineral Swim magnesium pool relieves eczema for 11 year old Eva'
		}
		if (slug === 'take-the-dead-sea-home') {
			return 'Hydrotherapy through Mineral Pools - Dive into Wellness'
		} else {
			return `${title}`
		}
	}
	return (
		<Layout className="PostTemplate">
			<Helmet>
				<title>{seoTitle()}</title>
				<meta name="description" content={description} />
				<meta name="og:site_name" content="Mineral Swim" />
				<meta name="keywords" content={keywords?.toString()} />
				<link rel="canonical" href={'https://mineralswim.com/' + slug + '/'} />
			</Helmet>
			<main className="bg-white">
				<section className="w-full pb-14">
					<div
						tw="relative flex items-center w-full bg-cover bg-no-repeat bg-center"
						css={css`
							background-image: url(${image.gatsbyImageData.placeholder
								.fallback});
							&::before {
								${tw`absolute inset-0`}
								content: '';
								background-color: rgba(255, 255, 255, 0.25);
								backdrop-filter: blur(16px);
							}
						`}
					>
						{' '}
						<GatsbyImage
							image={image.gatsbyImageData}
							tw="max-w-screen-md mx-auto self-center"
							loading="eager"
							alt={image.alt}
							title={image.title}
						/>
					</div>
					<article className="prose md:prose-xl text-gray-800 md:max-w-screen-md mx-auto px-2 pb-2">
						<h1 className="py-16 text-center font-display font-semibold mb-0">
							{seoH1()}
						</h1>
						{renderRichText(content, options)}
					</article>
				</section>
			</main>
		</Layout>
	)
}
export const PostQuery = graphql`
	query postQueryAU($slug: String!) {
		postData: allContentfulPost(
			filter: { slug: { eq: $slug }, node_locale: { eq: "en-AU" } }
		) {
			edges {
				node {
					title: seoTitle
					description: seoDescription
					keywords: seoKeywords
					slug
					category
					date: dateAndTime(formatString: "DD MMMM, YYYY")
					tag
					content {
						raw
						references {
							... on ContentfulAsset {
								contentful_id
								__typename
								file {
									contentType
									url
								}
								title
								alt: description
								gatsbyImageData(
									layout: CONSTRAINED
									width: 768
									quality: 50
									placeholder: BLURRED
									formats: [AUTO, WEBP]
								)
							}
						}
					}
					image {
						file {
							url
						}
						title
						alt: description
						gatsbyImageData(
							layout: CONSTRAINED
							width: 768
							quality: 50
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
				}
			}
		}
	}
`
export default PostTemplate
